<template>
  <div class="container-xl d-flex flex-column justify-content-center">
    <div class="col-12">
                <div class="card card-md">
                  <div class="card-stamp card-stamp-lg">
                    <div class="card-stamp-icon bg-primary">
                      <!-- Download SVG icon from http://tabler-icons.io/i/ghost -->
                      <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-trash-off" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <line x1="3" y1="3" x2="21" y2="21"></line>
                        <path d="M4 7h3m4 0h9"></path>
                        <line x1="10" y1="11" x2="10" y2="17"></line>
                        <line x1="14" y1="14" x2="14" y2="17"></line>
                        <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l.077 -.923"></path>
                        <line x1="18.384" y1="14.373" x2="19" y2="7"></line>
                        <path d="M9 5v-1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
                    </svg>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="row align-items-center">
                      <div class="col-10">
                        <h3 class="h1">{{ $t('badSourcePage.title') }}</h3>
                        <div class="markdown text-muted">
                          {{ $t('badSourcePage.text') }}
                        </div>
                        <div class="mt-3">
                          <a href="#" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modal-bad-source" rel="noopener">{{ $t('badSourcePage.createReportButton') }}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <!-- modal send -->
                <div class="modal modal-blur fade" id="modal-bad-source" tabindex="-1" style="display: none;" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">{{ $t('badSourcePage.modalCreateReport.title') }}</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="col">
                                <label class="form-label">{{ $t('badSourcePage.modalCreateReport.name') }}</label>
                                <input v-model="sendData.name" type="text" class="form-control">
                            </div>
                            <div>
                            <label style="margin-top: 10px;" class="form-label">{{ $t('badSourcePage.modalCreateReport.description') }}</label>
                            <textarea v-model="sendData.description" class="form-control"></textarea>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn me-auto" data-bs-dismiss="modal">{{ $t('badSourcePage.modalCreateReport.closeButton') }}</button>
                            <button @click="sendReport()" type="button" class="btn btn-primary" data-bs-dismiss="modal">{{ $t('badSourcePage.modalCreateReport.sendReportButton') }}</button>
                        </div>
                        </div>
                    </div>
                </div>
              <!-- -->

              <!-- modal success -->
                <div class="modal modal-blur fade" v-bind:class="{'show' : successModal.show}" id="modal-success" style="display: block;" v-bind:style="{'display' : successModal.display}" tabindex="-1" aria-hidden="true">
                    <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
                        <div class="modal-content">
                        <button type="button" class="btn-close" @click="closeSuccess()" data-bs-dismiss="modal" aria-label="Close"></button>
                        <div class="modal-status bg-success"></div>
                        <div class="modal-body text-center py-4">
                            <!-- Download SVG icon from http://tabler-icons.io/i/circle-check -->
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon mb-2 text-green icon-lg" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><circle cx="12" cy="12" r="9"></circle><path d="M9 12l2 2l4 -4"></path></svg>
                            <h3>{{ $t('badSourcePage.modalSuccess.header') }}</h3>
                            <div class="text-muted">{{ $t('badSourcePage.modalSuccess.text') }}</div>
                        </div>
                        <div class="modal-footer">
                            <div class="w-100">
                            <div class="row">
                                <div @click="closeSuccess()" class="col"><a href="#" class="btn w-100" data-bs-dismiss="modal">
                                    {{ $t('badSourcePage.modalSuccess.hideButton') }}
                                </a></div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
              <!-- -->
  </div>
</template>

<script>
export default {
    name: "SourcePage",
    data: function () {
      return {
        sendData: {
            name: null,
            description: null
        },
        successModal: {
            display: 'none',
            show: false
        }
      }
    },
    methods: {
        sendReport() {
            console.log(this.sendData)
            this.axios.get(document.location.protocol + '//scum-global.com/core/handler.php', {params: {request: "add_bad_source", info: this.sendData}}).then((response) => {
              if (response.data.success) {
                this.openSuccess()
              }
            })
        },
        openSuccess() {
            this.successModal.display = 'block'
            this.successModal.show = true
        },
        closeSuccess() {
            this.successModal.display = 'none'
            this.successModal.show = false
        }
    },
    created() {

    }
}
</script>